
.form-check-input:checked {
    background-color:#000;
    border-color: #000;
}
.form-check-input:focus {
    border-color: none !important;
    box-shadow: none !important;
}
.form-control:focus {
    box-shadow: none !important;
}
/* .form-control:focus {
    border:none;
    box-shadow: none;
} */
.form-switch {
    padding-left: 4em;
}
.form-switch .form-check-input:checked{
    font-size: x-large;
}
.form-switch .form-check-input {
    font-size: x-large;
}

.card{
    /* background-image:  linear-gradient(to bottom, #ece9e6, #ffffff);; */
    width: 45%;
    height: fit-content;
    margin: auto;
    color: #000;
    padding: 2%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}
.png_imgs{
    display: flex;
    width: 82%;
    justify-content: space-between;
}
.saveBtn{
    background-color: #000;
    color: #fff;
}
.saveBtn:hover{
    color: #fff;
}
.left{
    display: grid;
    margin-left: 10%;
    grid-gap: 70%;
    z-index: 1;
}
.right{
    display: grid;
    margin-right: 5%;
    grid-gap: 70%;
    z-index: 1;
}
.left img{
    animation: moving 2s 2s infinite ease-in-out alternate;
}

.right img{
    animation: moving 2s 2s infinite ease-in-out alternate;
}
@keyframes moving {
    from{
        transform: rotate(-30deg);
    }
    to{
        transform: rotate(30deg);
    }
}
.png2L{
    margin-left: 70%;
}
.png2R{
    margin-left: -70%;
}
.images {
    display: flex;
    bottom: 2%;
    width: 77%;
    justify-content: space-between;
}
.images  img{
    width: 30%;
    z-index: -1;
    float: left;
}

@media screen and (max-width: 992px){
    .card{
        width: 70%;
    }
    .left{
        margin-left: 0%;
    }
    .png2L{
        margin-left: 0%;
    }
    .png2R{
        margin-left: 0%;
    }
}
.install__btn{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: none;
    height: 7vh;
    width: 45%;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
}
.install__input{
    position: absolute;
    top: 0;
    left: 60%;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 10%;
    height: 6vh;
}

@media screen and (max-width: 648px){

    .left{
        display: none;
    }
    .right{
        display: none;
    }
    .images{
        display: none;
    }
    .install__btn{
        font-size: 16px;
        width: 60%;
    }
}
@media screen and (max-width: 500px){
    .card {
        width: 100%;
        height: fit-content;
    }
}