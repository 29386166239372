.panel {
    margin-bottom: 20px;
    margin-top: 40px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}
.panel-body{
    padding: 20px;
    line-height: 25px;
}
.ui-code{
    background-color: #0000001f;
}
u{
    font-weight: 500;
}
.btn-block{
    color: #fff;
    background-color: #000;
    margin: 20px;
}

.btn-block:hover{
    color: #fff;
    background-color: #000;
}