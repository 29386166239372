#exampleFormControlTextarea1 {
    margin-top: 20px;
    width: 70%;
}
.ui-code{
    padding: 12px;
}
.jumbotron{
    margin: 40px;
}
.buttonCss{
    background-color: #000;
    color: #fff;
}
.buttonCss:hover{
    color: #fff;
}