.cartRow{
  display: flex;
  height: fit-content;
  overflow-y: scroll;
}
.cartCol1{
  width: 15%;
}
.cartCol2{
  width: 75%;
  height: 100vh;
  mix-blend-mode: multiply;
}
@media screen and (max-width: 500px){
  .cartRow {
      font-size: 3vw;
  }
}