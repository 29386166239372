.btnSubmit{
    background-color: #000;
    color: #fff;
}
.btnSubmit:hover{
    background-color: #000;
    color: #fff;
}
.login{
    margin : auto;
    width: 50%;
    transform: translate(0%, 50%);
    background-color: #f3f3f3;
    border-radius:30px ;
}