.menu_bars{
  font-size: 3vw;
  color: #000;
}
.menu_bars:hover{
  color: #000;
}
.nav-link{
  color: #000 !important;
}
.slanted{
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  word-spacing: 10px;
  letter-spacing: 10px;
}
.slanted h1{
  font-weight: bolder;
  font-size: 4vw;
  margin-right: 12%;
}
.offcanvas{
  width: 15% !important;
  text-align: start;
}

.sidebar {
    margin: 0;
    padding: 0;
    width: 13% !important;
    background-color: #fafafa;
    position: fixed;
    height: 100vh;
  }
  
  .sidebar a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
  }


  .sidebar__end{
    position: absolute;
    bottom: 3%;
    left: 3%;
  }
  @media screen and (max-width: 900px){
    .offcanvas {
      width: 20% !important;
    }
    .offcanvas-body ul{
      padding: 0;
      margin-left: -18%;
    }
    .sidebar__end {
      writing-mode: tb;
      transform: rotate(180deg);
      left: 50%;
  }
  }
  @media screen and (max-width: 648px){
    
    .offcanvas {
        width: 60% !important;
    }

  }
  @media screen and (max-width: 500px){
    
    .offcanvas {
      width: 30% !important;
  }

  }
  
  /* @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}
  }
  
  @media screen and (max-width: 400px) {
    .sidebar a {
      text-align: center;
      float: none;
    }
  } */